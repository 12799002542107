<!-- @format -->
<script lang="ts">
  import type { StoresKey } from "~/stores";

  import Title from "../common/Title.svelte";
  import TwoItemCarousel from "../carousels/TwoItemCarousel.svelte";
  import TwoItemCardLink from "../carousels/TwoItemCardLink.svelte";
  import TwoItemCard from "../common/TwoItemCard.svelte";
  import type { SurfHouse } from "~/mall-of-tripla";
  import type { CarouselInfo } from "../carousels/utils";

  let class_list = "";
  export { class_list as class };
  export let request_store_key: StoresKey;
  export let carouselInfo: CarouselInfo;
  export let surfHouseEvents: SurfHouse[];
  const {
    titleTextColor,
    textColor,
    textboxBackgroundColor,
    linkTextbox,
    linkTitle,
    logo,
  } = carouselInfo;
  
</script>

{#if surfHouseEvents && surfHouseEvents.length && linkTextbox}
  <div class="py-6 {class_list}">
    {#if linkTitle}
      <Title {linkTitle} {titleTextColor} />
    {/if}
    <TwoItemCarousel>
      <svelte:fragment>
        {#each surfHouseEvents as event, index}
          <swiper-slide>
            <TwoItemCard
              props={{
                image: event.image.thumbnail,
                link: event.link,
                title: event.heading,
                description: event.excerpt.plain,
              }}
              {request_store_key}>
            </TwoItemCard>
          </swiper-slide>
          <swiper-slide class="space" />
          {#if index == 0}
            <swiper-slide>
              <TwoItemCardLink
                {textColor}
                backgroundColor={textboxBackgroundColor}
                {linkTextbox}
                {logo} />
            </swiper-slide>
            <swiper-slide class="space" />
          {/if}
        {/each}
      </svelte:fragment>
    </TwoItemCarousel>
  </div>
{/if}
